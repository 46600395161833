












import { Component, Vue } from 'vue-property-decorator'

import SubscriptionTypeItemForm from '@/partials/forms/Events/SubscriptionTypeItemForm.vue'

@Component({
  components: {
    SubscriptionTypeItemForm
  }
})
export default class SubscriptionItemsCreate extends Vue {

}
